.notification {
  margin-bottom: 36px;
  padding: 0 10px;
  &:last-child {
    margin-bottom: 0;
  }
  & > div > div {
    background: transparent;
  }
}
.title {
  margin: 0 0 12px 0;
  padding: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.description {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  display: inline-block;
}
