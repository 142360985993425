.container {
  margin-bottom: 200px;
}

@mixin widgetBoxCommon() {
  margin-bottom: 38px;
  padding: 21px 24px 30px 24px;

  border: 1px solid rgba(255, 255, 255, .07);
  border-radius: 4px;
  background: #171717;
}

.widgetBox {
  @include widgetBoxCommon;
  width: 408px;
}

.widgetBoxFullScreen {
  @include widgetBoxCommon;
  width: 100%;
}

.fieldsContainer {
  display: flex;
  justify-content: space-between;
}


@mixin fieldBoxCommon() {
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }
}

.fieldBox {
  @include fieldBoxCommon;
}

.fieldBoxHalfSize {
  width: 168px;

  @include fieldBoxCommon;
}

.fieldBoxMB0 {
  @include fieldBoxCommon;
  margin-bottom: 0;
}

.wrapperButtonFooter {
  display: flex;
  justify-content: space-between;
  margin: 46px 0 150px;
}
