h1, h2, h3 {
  margin: 0;
  padding: 0;
}
.wrapper {
  min-width: 945px;
  @media (max-width: 1220px) {
    min-width: 845px;
  }
  @media (max-width: 1115px) {
    min-width: 780px;
  }
  @media (max-width: 1000px) {
    min-width: 150px;
  }
}
.title {
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  font-family: 'Bebas Neue', cursive;
  margin-bottom: 14px;
}
.redirectBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 33px 24px;
  margin-bottom: 24px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  & .subtitle {
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    font-family: 'Bebas Neue', cursive;
    margin-bottom: 5px;
  }
  & .descrText {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #686868;
  }
}
.container {
  display: flex;
}
.favicon {
  flex-basis: 50%;
  margin-right: 30px;
  & .faviconBlock {
    padding: 24px 12px 36px 12px;
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  & .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 38px;
    img {
      margin-bottom: 13px;
    }
  }
  & .faviconDescr {
    font-size: 12px;
    line-height: 14px;
    color: #686868;
  }
  & .faviconDescrSupport {
    font-size: 12px;
    line-height: 14px;
    color: #686868;
    margin-bottom: 9px;
  }
  & .boxBtn {
    width: 171px;
    margin: 0 auto;
  }
}
.domain {
  flex-basis: 100%;
  & .domainBlock {
    padding: 24px 24px 36px 24px;
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 4px;
    & .fieldSize {
      input {
        width: 100%;
      }
    }
    & .fieldSizeCopy {
        & input {
          //width: 315px;
        }
    }
  }
  & .fieldItem {
    margin-bottom: 34px;
    & .itemTitle {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
      display: inline-block;
    }
    & .fieldLink {
      font-size: 12px;
      line-height: 14px;
      text-decoration-line: underline;
      color: #686868;
      margin-top: 12px;
      display: inline-block;
    }

    & .baseDomain {
      margin-top: 19px;
      font-weight: 700;
      @media (max-width: 767px) {
        margin-top: 15px;
      }
    }
  }
  & .boxBtn {
    text-align: end;
    button {
      width: 93px;
    }
  }
}
//COMMON FOR BLOCKS
.blockTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 19px;
  @media (max-width: 767px) {
    font-size: 25px;
  }
}
