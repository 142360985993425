.conditions {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: 500;
    display: inline-block;
  }

  &__descr {
    margin-bottom: 24px;
  }
}
.inner {
  margin-bottom: 36px;
}
.link {
  color: #3598DB;
  text-decoration: underline;
  font-weight: 500;
}
.list {
  margin: 0 0 24px 0;
  padding: 0 0 0 27px;
}
