.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  .pageTitle {
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    line-height: 30px;
    font-style: normal;
    align-content: flex-start;
  }

  .searchWrapper {
    display: flex;
    justify-content: flex-end;

    input {
      width: 200px;
      margin-right: 36px;
    }
  }
}

.wrapperFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dateInner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;

    .label {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    div {
      margin-right: 5px;

      div {
        div:first-child {
          font-size: 12px;
          line-height: 14px;
          transition: 0.3s ease-out;
          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
      }
    }
  }

  .selectItem {
    //margin-bottom: 24px;
    width: 248px;
    z-index: 99999;
  }

  .titleItem {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
    display: inline-block;
  }

}

