.title {
  padding: 0;
  margin: 0;
  font-family: 'Bebas Neue', cursive;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 31px 0 27px 0;
}
.subtitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  margin: 0 0 20px 0;
}
.saleTracks {
  margin-bottom: 24px;
}
