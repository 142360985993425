.AdvertisingBeat {
  padding: 22px 12px 26px 12px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}
.info {
  margin-bottom: 30px;
  & .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    & .title {
      font-size: 14px;
      line-height: 16px;
    }
    & .value {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }
    & .trackName {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-decoration-line: underline;
    }
  }
}

.info.disable {
  opacity: 0.3;
}
.btnBox {
  text-align: end;
  & .active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
    & span {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }
  }
  & .hidden {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & span {
      display: none;
    }
  }
}
.hideContent {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    & .title {
      font-size: 14px;
      line-height: 16px;
    }
    & .value {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
