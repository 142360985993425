.GroupDiscount {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 22px 12px 26px 12px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}
.list {
  margin-bottom: 30px;
  .item {
    display: grid;
    grid-template-columns: 47% 53%;
    margin-bottom: 17px;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .descr {
      font-size: 14px;
      line-height: 16px;
    }
    .value {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }
    .licenses {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.btnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .icons {
    min-height: 16px;
    min-width: 16px;
    font-size: 16px;
  }

  button {
    padding: 0;
    &:first-child {
      margin-right: 21px;
    }
  }
}
