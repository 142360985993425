.loader {
    position: absolute;
    left: 50%;
    top: 50%;

    width: 60px;
    height: 75px;
    margin: 0 auto;

    .line {
        position: absolute;
        bottom: 0;

        width: 10px;

        border-radius: 2px;
        background-color: var(--highlight);
    }

    .line1 {
        left: 0;

        -webkit-animation: line-grow .5s ease alternate infinite;
        animation: line-grow .5s ease alternate infinite;
    }

    .line2 {
        left: 20px;

        -webkit-animation: line-grow .5s .2s ease alternate infinite;
        animation: line-grow .5s .2s ease alternate infinite;
    }

    .line3 {
        left: 40px;

        -webkit-animation: line-grow .5s .4s ease alternate infinite;
        animation: line-grow .5s .4s ease alternate infinite;
    }
}


@keyframes line-grow {
    0% {
        height: 0;
    }
    100% {
        height: 75%;
    }
}

@-webkit-keyframes line-grow {
    0% {
        height: 0;
    }
    100% {
        height: 75%;
    }
}
