.Button {
  padding: 10px 32px 10px 32px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: pointer;
  min-width: 135px;
  white-space: nowrap;
}

.Button.disabled {
  background: #282828;
  opacity: 0.5;
  white-space: nowrap;
  cursor: auto;
}

.Button.Primary {
  background: var(--highlight);
  transition: 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    opacity: 0.7;
  }

}

.Button.Delete {
  background: #DC1749;
  transition: 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    opacity: 0.7;
  }
}

.Button.Secondary {
  background: #383838;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    opacity: 0.7;
  }
}

.Button.Details {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 2px;
  min-width: 55px;
  padding: 4px 6px 4px 6px;
  transition: 0.3s ease-out;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    opacity: 0.7;
  }
}

.inputButton {
  display: none;
}

.browseButton {
  cursor: pointer;
  color: var(--highlight);
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
}

.browseButtonOutline {
  padding: 5px 32px 5px 32px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: pointer;
  min-width: 135px;
  background: #162027;
  border: 1px solid #3598DB;
  box-sizing: border-box;
  width: 100%;

  &:hover {
    cursor: pointer;
    background: #1d2831;
  }
}

.Outline {
  background: #162027;
  border: 1px solid #3598DB;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  width: 100%;
  transition: 0.3s ease-out;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    opacity: 0.8;
  }
}
.buttonBox {
  position: relative;
  display: flex;
  align-items: center;
  .buttonTooltip {
    padding: 0;
    width: 18px;
    height: 18px;
  }
  .tooltip {
    position: absolute;
    top: 25px;
    left: -13px;
    padding: 8px 12px;
    width: 300px;
    background: #171717;
    border: 1px solid #5C5C5C;
    z-index: 15;
    border-radius: 4px;
    @media (max-width: 767px) {
      width: 300px;
    }
  }
  .description {
    font-size: 12px;
    line-height: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-word;
    max-height: 176px;
  }
  .triangle {
    width: 11px;
    height: 11px;
    border-radius: 2px;
    position: absolute;
    transform: rotate(45deg);
    background: #171717;
    border-top: 1px solid #5C5C5C;
    border-left: 1px solid #5C5C5C;
    top: -5px;
  }
}

