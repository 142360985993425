.TimePicker {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 12px;
  }

  & select {
    background: #282828;
  }
}
