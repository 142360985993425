.box {
  display: flex;
  justify-content: space-between;

  padding: 22px 0 22px 12px;

  background: #101010;
  box-shadow: 0 1px 0 rgba(255, 255, 255, .1);
  margin-bottom: 1px;
  align-items: center;
}

.title {
  font-size: 14px;
  font-weight: 700;
}

.icon {
  margin-right: 15px;
}
