.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  .pageTitle {
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    line-height: 30px;
    font-style: normal;
    align-content: flex-start;


  }

  .searchWrapper {
    display: flex;
    justify-content: flex-end;

    input {
      width: 200px;
      margin-right: 36px;
    }
  }

}

.tooltipWrapper {
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  .info {
    display: flex;
    align-items: center;

    & label {
      margin-left: 5px;
      font-size: 14px;
    }

    & img {
      cursor: pointer;
    }
  }

  .insideWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 24px;

    & a {
      color: #FFFFFF;

      &:hover {
        opacity: 0.8;
      }
    }
  }

}

.pagination {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 12px;
}

.detail {
  padding: 24px;
  background: #101010;
  border: none;
  color: #FFFFFF;
  text-align: right;
}

.emptyMessage {
  text-align: center;
  color: white;
}