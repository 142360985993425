.notificationsBox {
  display: flex;
  flex-direction: column;
}
.notifications {
  overflow: auto;
  margin-bottom: 24px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 19px 30px 36px 30px;
}
.btnBox {
  text-align: end;
}
