@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');

h2 {
  margin: 0;
  padding: 0;
}

.wrapper {
  margin-top: 36px;
  margin-bottom: 50px;
  min-width: 945px;

  @media (max-width: 1220px) {
    min-width: 845px;
  }

  @media (max-width: 1115px) {
    min-width: 780px;
  }
}

.wallet {
  display: grid;
  grid-template-columns: 25% 1fr;
  row-gap: 54px;

  & .walletMethod {
    padding: 0 36px 0 0;
    border-right: 2px solid rgba(255, 255, 255, 0.1);

    .methods {
      margin: 10px;
    }
  }

  & .walletForm {
    padding: 0 0 0 36px;
  }

  & .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }

  & .emailForm {
    display: flex;
    flex-direction: column;
    margin-bottom: 27px;

    & .emailLabel {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }

  & .steps {

    & .step {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      & .stepTitle {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
      }

      & .stepDescr {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.inputSize {

  input {
    width: 241px;
  }
}

.btnBox {
  grid-area: 2/2/3/4;
  justify-self: end;
  margin-top: 30px;
}

.item {
  width: 195px;
  height: 108px;
  border: 1px solid #3598DB;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.instructionContainer {
  margin-left: 34px;
  word-break: break-word;

  .commonInfo {
    margin-bottom: 37px;

    .title {
      margin-bottom: 20px;
      line-height: 24px;
    }

    .list {
      list-style-position: inside;
      padding: 0;
      margin: 0;
      line-height: 24px;
    }
  }

  .link {
    color: #3598DB;

    &:hover {
      text-decoration: underline;
    }
  }

  .step {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .stepTitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }

    .stepText {
      font-size: 14px;
      line-height: 24px;
      text-align: justify;
    }

    .inputBox {
      width: 100%;
      margin-top: 17px;

      label {
        display: inline-block;
        font-size: 12px;
        line-height: 14px;
      }

      & input {
        width: 100%;
      }
    }

    .copyInputBox {

      .disabledLabel {
        opacity: .5;
        user-select: none;
        display: inline-block;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .fieldsBox {
      justify-content: center;
      align-items: center;
      display: flex;
      gap: 30px;

      .inputBox {
        width: 50%;
      }
    }

    .imgBox {
      margin-top: 17px;
    }
  }

  .btnBox {
    text-align: end;
  }
}

.imgStyle {
  width: 60%;
  height: 60%;
}
