.wrapper {
  width: 395px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  background: #171717;
  max-height: calc(100% - 64px);
  overflow: auto;
  @media (max-width: 567px) {
    width: 330px;
  }
}
p {
  margin: 0;
}
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 19px 24px 18px 24px;
  & .title {
    font-size: 18px;
  }
  & .headerDescription {
    font-size: 14px;
    margin-top: 10px;
  }
}
button {
  background: transparent;
  cursor: pointer;
}
.description {
  padding: 23px 20px 36px 20px;
  font-size: 14px;
  line-height: 24px;
  word-break: break-word;

  & .text {
    margin-bottom: 16px;
    & .boldText {
      font-weight: 700;
    }
  }
}
.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

