.LicenseCard {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 22px 12px 26px 12px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}
.options {
  margin-bottom: 30px;
  & .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    & .title {
      font-size: 14px;
      line-height: 16px;
    }
    & .value {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
.btnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & img:first-child {
    margin-right: 6px;
  }
}
