.DatePicker {
  display: flex;
  flex-direction: column;

  .dateInput {
    width: 160px;
    background: #171717;
    border-radius: 2px;
    height: 36px;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: 0.3s ease-out;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      opacity: 0.8;
    }



    button {
      color: #FFFFFF;
    }

    input {
      padding: 10px 17px 10px 17px;
      font-weight: 700;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      border: none;
      color: #FFFFFF;
      border-radius: 2px;
      &:active, :hover, :focus {
        outline: 0;
        outline-offset: 0;
      }

      &:disabled {
        color: #FFFFFF!important;
      }
    @media (max-width: 767px) {
      padding: 10px 0 10px 7px;
    }
    }

  }

  @media (max-width: 567px) {
    .dateInput {
      width: 120px;
    }
  }
}



.MuiPaper-root {
  background-color: #282828;
}
