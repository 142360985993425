.modalDiscount {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  width: 393px;
  border-radius: 4px;
  padding: 19px 0 48px 0;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 18px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  & .title {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }
}

.modalInner {
  padding: 19px 24px 0 24px;
}

.titleItem {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  display: inline-block;
}

.inputItem {
  display: flex;
  flex-direction: column;
}

.inputSize {
  & input {
    width: 121px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
  }
}

.selectItem {

  select {
    width: 100%!important;
  }
}

.inputItems {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.btnBox {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
