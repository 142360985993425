h1 {
  margin: 0;
  padding: 0;
}

.title {
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  font-family: 'Bebas Neue', cursive;
  margin-bottom: 14px;
}

.calcBlock {
  display: flex;
  justify-content: space-between;
}

.fieldsText {
  margin-bottom: 10px;
}

.itemTitle {
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
}

.tooltipBox {
  margin-top: 11px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.fieldItemsBox {
  display: flex;
  align-items: flex-end;
  gap: 24px;
}

.fieldsText {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 54px;
}

.balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  line-height: 16px;

  & .balanceCount {
    margin-top: 9px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    line-height: 26px;
  }
}

.coins {
  margin-left: 12px;
}

.fieldItem {

  input {
    width: 131px;
  }

  &:first-child {
    position: relative;

    &:after {
      content: '=';
      position: absolute;
      top: 54%;
      left: 106%;
    }
  }
}

.sale {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 12px;
  max-width: max-content;
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: black;
  border-radius: 2px;
  background-color: #FEC32D;
  margin-bottom: 24px;
  text-transform: uppercase;
}