.textAreaField {
  resize: none;
  background: #282828;
  height: 132px;
  border-radius: 2px;
  width: 100%;
}

.labelStyle {
  display: block;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}