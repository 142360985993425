.MySalesPayment {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px 24px 21px 24px;
}
.list {
  margin-bottom: 72px;
}
.item {
  padding: 13px 0 11px 0;
  background: #171717;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .title {
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5)
  }
  & .price {
    display: flex;
    align-items: center;

    & img {
      margin-left: 3px;
    }
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
  & .promo {
    display: flex;
    align-items: center;
    & img {
      margin-left: 3px;
    }
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  & .cashback {
    display: flex;
    align-items: center;
    & img {
      margin-left: 3px;
    }
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
  & .payVia {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
}
.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .text {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }
  & .value {
    font-weight: bold;
    font-size: 30px;
    line-height: 24px;
    font-family: 'Bebas Neue', cursive;
    & .usdIcon {
      width: 20px;
      height: 26px;
      position: relative;
      top: 3px;
      margin-left: 0;
    }
    & .rubIcon {
      width: 20px;
      height: 26px;
      position: relative;
      top: 2px;
      margin-left: 3px;
    }
  }
}
