.modalDiscount {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  width: 393px;
  border-radius: 4px;
  padding: 19px 0 48px 0;
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 18px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  & .title {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }
}
.modalInner {
  padding: 0 24px;
}
.selectItem {
  margin-bottom: 24px;
}
.dateInner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  div {
    div {
      div:first-child {
        font-size: 12px;
        line-height: 14px;
      }
  }
  }

  .fieldItemTitle {
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }
}
.discountsInner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.couponInner {
  display: flex;
  flex-direction: column;
  & input {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    border-radius: 2px;
  }
}
.titleItem {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  display: inline-block;
}
.inputSize {
  & input {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    border-radius: 2px;

  }
}
.discountItem:first-child {
  @media (max-width: 767px) {
    grid-area: 1/1/2/3;
    margin-bottom: 24px;
  }
}
.discountItem:last-child {
  & .inputSize {
    position: relative;
    &::after {
      content: '/';
      position: absolute;
      font-size: 14px;
      line-height: 24px;
      top:50%;
      left: -13px;
    }
  }
}
.btnsBox {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
