.Input {
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background: #282828;
  height: 36px;
  color: #FFFFFF;
  padding: 10px 17px 10px 17px;
  border: 1px solid transparent;
  transition: 0.3s ease-out;
  text-overflow: ellipsis;
  width: 100%;

  &:hover {
    opacity: 0.8;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }

  &:focus {
    opacity: 0.8;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }

  &:disabled {
    background: rgba(41, 41, 41, 0.5);
    color: rgba(255, 255, 255, 0.5);
    user-select: none;
    pointer-events: none;
  }
}

@mixin commonSearch {
  background: #171717 url(../../../assets/icons/search.svg) no-repeat scroll 17px 12px;
  padding-left: 40px;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
  }
}

.search {
  @include commonSearch;
}

.lightSearch {
  @include commonSearch;
  background: #2e2e2e url(../../../assets/icons/search.svg) no-repeat scroll 17px 12px;
  width: 100%;
  color: white;
  padding: 5px 0 0 45px;

  &::placeholder {
    color: white;
  }
}

.priceDollar {
  background: #282828 url(../../../assets/icons/usd.svg) no-repeat scroll 17px 10px;
  padding-left:30px;
}

.priceRub {
  background: #282828 url(../../../assets/icons/rub.svg) no-repeat scroll 15px 12px;
  padding-left:30px;
}

.wrapperCopy {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .copy {
    width: 85%;
  }

  .copyButton {
    -webkit-appearance:none!important;
    color:var(--highlight);
    text-align:right;
    border-left:0;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    height: 36px;
    background: #282828;
    border: none;
    padding: 10px 10px 10px 10px;
  }
  
  .copyButton:active {
    opacity: 0.7;
  }

  .copyButton.disabled {
    background: rgba(41, 41, 41, 0.5);
    color: rgba(255, 255, 255, 0.5);
    user-select: none;
    pointer-events: none;
    width: 15%;
    white-space: nowrap;
  }
}

.error {
  border: 1px solid red;
}

.errorMessage {
  color: red;
}

.inputField {
  background: #282828;
}

.tagInputField {
  margin-right: 24px;
}

.tagInputWrapper {
  display: flex;
  flex-wrap: nowrap;
}

.tagsData {
  position: absolute;
  top: 62px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  padding: 5px 10px;
  background-color: #2a2a2a;
  z-index: 999;

  & > div {
    margin: 10px 5px;
    &:hover {
      cursor: pointer;
      color: var(--highlight)
    }
  }

}

.tagsWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 380px;

  .tag {
    cursor: default;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 30px;
    padding: 5px 10px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;

    img {
      width: 10px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}



