.modalBodyDescription {
  display: flex;
  gap: 5px;
  margin-bottom: 40px;
}

.modalButtonsBox {
  display: flex;
  justify-content: space-between;
}
