.root {
    min-width: 100%;
    margin-top: 25px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.tableWrapper {
    min-width: 100%;
    margin-top: 0;
    background: white
}

.red {
    background: #dd5758;
    padding: 4px;
    color: white;
    border-radius: 3px;
}
