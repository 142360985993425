.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;

  .item {
    display: flex;
    flex-direction: column;

    & .descr {
      font-size: 14px;
      line-height: 16px;
    }

    & .value {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      display: inline-block;
      margin-top: 12px;
    }
  }

  .tooltipBox {
    display: flex;
    align-items: center;

    & > .descr {
      margin-left: 6px;
    }
  }
}

.calculate {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  position: relative;
  margin-bottom: 24px;

  &::after {
    content: '=';
    position: absolute;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 28%);
  }

  .conversion {
    display: flex;
    flex-direction: column;

    & .size input {
      width: 100%;
      padding: 10px;
    }

    .descr {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }
}

.btnBox {
  margin-bottom: 36px;

  button {
    width: 100%;
  }
}

.sale{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: black;
  border-radius: 2px;
  background-color: #FEC32D;
  margin-bottom: 12px;
  text-transform: uppercase;
}