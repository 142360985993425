.btnBox {
  margin-bottom: 24px;
  & button {
    width: 100%;
  }
}

.tooltipWrapper {
  font-family: 'Roboto', cursive;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  & label {
    margin-left: 5px;
  }

  .insideWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & a {
      color: #FFFFFF;

      &:hover {
        opacity: 0.8;
      }
    }
  }

}
