.inputSize {
  margin-bottom: 36px;
  input {
    width: 100%;
    background: #282828 url('../../../assets/icons/search.svg') no-repeat scroll 17px 12px;
  }
}
.selectBox {
  margin-bottom: 24px;
  .selectDescr {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
  }
  .selectSize {
    & > div > div {
      background-color: transparent;
    }
  }
}
.btnBoxAdd {
  margin-bottom: 36px;
  button {
    width: 100%;
  }
}
