.wrapper {
  min-width: 945px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    min-width: 113px;
  }

}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
  & .title {
    margin: 0;
    padding: 0;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    font-family: 'Bebas Neue', cursive;
    @media (max-width: 767px) {
      align-self: flex-start;
    }
  }
  .btnBox {
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 29px;
    }
  }
}
.subtitleBox {
  flex-grow: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  & .subtitle {
    border-bottom: 2px dashed #FFFFFF;
    margin-right: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}
.hideContent {
  margin-bottom: 21px;
  & .item {
    margin-bottom: 8px;
      .itemTitle {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        cursor: pointer;
      }
    .itemDescription {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
  }
  & .notification {
    margin-top: 2px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    opacity: 0.5;
  }
}
.wrapperLangTamplate {
  & label {
    font-size: 12px;
  }
  & select {
    width: 190px!important;
  }
}
.ButtonsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
}

.toolbarEditor {
  background: #000000!important;
  border: none!important;
  color: black!important;
  max-width: 1050px;

  @media (max-width: 1000px) {
    max-width: 100%;
  }
}

.wrapperClassName {
  //background: #171717!important;
}

.editorClassName {
  background: #171717!important;
  color: #FFFFFF;
  min-height: 600px;
  max-height: 600px;
  max-width: 1062px;
  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: 1000px) {
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

