.wrapper {
  margin-top: 36px;
  margin-bottom: 50px;
  min-width: 945px;
  @media (max-width: 1220px) {
    min-width: 845px;
  }
  @media (max-width: 1115px) {
    min-width: 780px;
  }
}
.notificationsBox {
  display: flex;
  flex-direction: column;
}
.notifications {
  max-width: 430px;
  min-width: 430px;
  min-height: 648px;
  max-height: 650px;
  overflow: auto;
  margin: 0 auto;
  padding-top: 9px;
}
.btnBox {
  text-align: end;
  max-width: 430px;
  min-width: 430px;
  margin: 0 auto 0 auto;
}
