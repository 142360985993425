.menu {
  display: block;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-sizing: border-box;
  border-radius: 4px;
  max-height: 460px;
  min-width: 190px;
  padding: 13px 20px 13px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 1139px) {
    padding: 55px 20px 13px 0;
    max-height: 100%;
    overflow: auto;
    border: 1px solid transparent;
  }

  @media (max-width: 375px) {
    border: none;
    margin-left: 8px;
  }

  .link {
    text-decoration: none;
    color: #FFFFFF;

    &:focus-within, &:focus, &:focus-visible {
      opacity: 1;
      font-weight: bold;
    }
  }

  .item {
    margin-bottom: 12px;
    opacity: 0.6;
    padding-left: 20px;
    transition: 0.3s ease-out;

    @media (max-width: 1139px) {
      opacity: 1;
      margin-bottom: 24px;
      font-size: 16px;
    }

    a {
      display: flex;
      align-items: center;
      color: #FFFFFF;

    }

    &:hover {
      color: #FFFFFF;
      opacity: 1;
    }
  }

  .active.item {
    opacity: 1;
    border-left: 1px solid #FFFFFF;

    @media (max-width: 1139px) {
      border-left: 0 solid transparent;
    }
  }

}

.navLinkIcon {
  margin-left: 8px;
  width: auto;
  height: 16px;
}

.icon {
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(100deg) brightness(100%) contrast(100%);
  margin-left: 8px;
  width: 16px;
  height: 16px;
}