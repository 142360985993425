.amountsBox {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 20px 24px 58px 24px;
  margin-bottom: 12px;
  & .text {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.4;
    margin-bottom: 12px;
    display: inline-block;
  }
  & .price {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
  }
  & .usd, .rub {
    display: flex;
    align-items: center;
  }
  & .usd img {
    width: 13px;
    height: 26px;
    margin-right: 4px;
  }
  & .rub img {
    width: 16px;
    height: 26px;
    margin-right: 4px;
  }
}
.btnBoxWithdraw {
  button {
    width: 100%;
    margin-bottom: 24px;
  }
}
.stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 12px;
  margin-bottom: 48px;
  & .item {
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 4px;
    padding: 20px 18px 24px 18px;
    display: flex;
    flex-direction: column;
    & .titleItem {
      opacity: 0.4;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 12px;
    }
    & .count {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      & img {
        margin-right: 4px;
        width: 16px;
        height: 26px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.boxBtnStats {
  text-align: end;
  margin-bottom: 24px;
  & button {
    width: auto;
    padding: 10px 32px;
    white-space: nowrap;
  }
}

.trackInfo {
  margin-bottom: 18px;
}
