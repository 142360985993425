.LicenseItem {

  .licenses {
    display: flex;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
  }

  .contentWrapper {
    background: #101010;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 2px;
    max-width: 263px;
    min-width: 263px;
    margin-right: 10px;

    .licenseName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #171717;
      border-radius: 2px 2px 0 0;
      font-family: 'Bebas Neue', cursive;
      font-style: normal;
      font-size: 30px;
      line-height: 30px;
      min-height: 134px;
      text-align: center;
      padding-left: 24px;
      padding-top: 37px;
      padding-bottom: 37px;

      p {
        max-width: 190px;
        overflow: hidden;
        max-height: 60px;
        text-overflow: ellipsis;
      }
    }

    .filesWrapper {
      padding: 24px 24px 24px 24px;
      min-height: 148px;
      overflow: auto;
    }

    .costWrapper {
      background: #171717;
      border-radius: 0 0 2px 2px;
      color: #FFFFFF;
      padding: 24px;
      font-size: 12px;
      min-height: 148px;

      .cost {
        margin-bottom: 10px;
      }

      span {
        font-size: 14px;
      }
    }

    .contentWrapperBlur {
      opacity: 0.5;
      pointer-events: none;
      min-height: 148px;
    }

    .costWrapperInput {
      display: flex;

      & input {
        margin-right: 5px;
      }
    }
  }

  .arrow {
    cursor: pointer;
    width: 15px;

    &:hover {
      opacity: 0.8;
    }
  }

  .arrowLeft {
    transform: rotate(180deg);
  }

  .licenseDescr {
    margin-bottom: 16px;
  }
}

.titleBlock {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #FFFFFF;
  margin-top: 37px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 24px;
    margin-top: 0;
  }

  span {
    border-bottom: 2px dashed #FFFFFF;
    margin-right: 8px;
  }
}