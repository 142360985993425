.title {
  display: inline-block;
  padding: 0;
  margin: 0 0 19px 0;
}
.subtitle {
  display: inline-block;
  padding: 0;
  margin: 0 0 18px 0;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  font-family: 'Bebas Neue', cursive;
}
.headerBox {
  display: flex;
  flex-direction: column;
}
.description {
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  color: #686868;
  margin-bottom: 11px;
}
.iconBox {
  padding: 24px 12px 36px 12px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 41px;
  & img {
    margin-bottom: 19px;
  }
  & .imgSupports {
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 9px;
  }
  & .imgSize {
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 32px;
  }
}
.domainBox {
  padding: 25px 24px 36px 24px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  & .fieldItem {
    margin-bottom: 36px;
    input {
      width: 100%;
    }
    .descr {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }
    .link {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      text-decoration-line: underline;
      color: #686868;
      margin-top: 12px;
    }
  }
  & .btnBox {
    text-align: end;
    margin-bottom: 0;
    button {
      min-width: 95px;
      width: 95px;
      padding: 10px 32px;
    }
  }
}
.btnBox {
  margin-bottom: 41px;
  button {
    width: 100%;
  }
}
