.cropperWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .wrapperButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
}

.cropper {
  background: #262626;
  border: 1px solid rgba(255, 255, 255, 0.07);
  height: 200px;

}

.cropperImage {
  background: #262626;
  border: 1px solid rgba(255, 255, 255, 0.07);
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
