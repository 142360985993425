.mobileMenu {
  width: 100%;
  margin-bottom: 24px;
  overflow: hidden;
}

.list {
  display: flex;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;

  & .item, .item > a {
    white-space: nowrap;
    padding: 0 9px 18px 9px;
    margin: 0 24px 0 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    border-bottom: 2px solid transparent;

    &:last-child {
      margin: 0 0 0 0;
    }

    & a {
      opacity: .4;
    }
  }


}

.item.active {
  border-bottom: 2px solid white;

  & a {
    opacity: 1;
  }
}

.list::-webkit-scrollbar {
  width: 0;
  display: none;
}

div > .menuButton {
  padding: 4px;
  background: white;

  & span {
    color: black;
  }
}
