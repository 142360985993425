.PublicationSettings {

  p {
    margin-bottom: 18px;
  }

  .wrapperButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }

  .wrapperDateTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div > div > div > div:nth-child(1) {
      background: #282828;
      border: transparent;
    }
    @media (max-width: 567px) {
      & > div > div > div > div:nth-child(1) {
        width: 120px;
        background: #282828;
        border: transparent;
      }
    }
    .label {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    .fieldItemTitle {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }

  }
}
