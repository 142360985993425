.contentWrapper {
  .selectWrapper {

    .title {
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
      padding-left: 1px;
    }

    .textFieldWrapper {
      position: relative;

      .inputWrapper {
        input {
          padding: 10px;
        }

        margin-bottom: 24px;
      }

      .errorMessage {
        position: absolute;
        bottom: -20px;
        text-transform: lowercase;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        color: red
      }
    }

    .select {
      [class$="-control"] {
        background-color: #171717;
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-sizing: border-box;
        box-shadow: none;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
        border-radius: 2px;
        margin-bottom: 24px;

        &:hover {
          border: 1px solid rgba(255, 255, 255, 0.3);
        }
      }

      [class$="-singleValue"] {
        color: white;
      }

      [class$="-option"] {
        background-color: #171717;
        height: 36px;
        z-index: 26;
        color: #FFFFFF;

        &:hover {
          background-color: #282828;
          cursor: pointer;
        }
      }
    }

    .buttonsWrapper {
      margin-top: 36px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}