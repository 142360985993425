.beatsBox {
  overflow-y: auto;

  height: 500px;
  margin-bottom: 25px;
}

.searchBox {
  margin-bottom: 25px;
  width: 100%;
}

.checkBox {
  margin: 0 0 25px -10px;
  font-size: 12px;
}

.btnBox {
  display: flex;
  justify-content: space-between;
}
