.trackDescr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.trackDescrMob {
  flex-direction: column;

  margin-bottom: 20px;

  text-align: center;
}

.trackCover {
  width: 50px;
  height: 50px;
  margin-right: 16px;

  border-radius: 2px;

  object-fit: cover;
}

.trackCoverMob {
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
}

.trackText {
  font-size: 14px;
  font-weight: bold;

  color: var(--main-text);
}

.trackArtist,
.trackTitle {
  margin: 0;
}

@media (max-width: 576px) {
  .trackCover {
    display: none;
  }
}
