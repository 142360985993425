.beatsHeader {
  display: grid;
  grid-template-columns: 34% 15% repeat(2, 15%) auto;
  height: 36px;
  background: #171717;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #313030;
  @media (max-width: 1279px) {
    grid-template-columns: 38% 18% repeat(2, 18%) auto;
  }

  .chartPlace, .download, .listens {
    display: flex;
    align-items: center;
    & img {
      margin-right: 12px;
    }

  }

  .name {
    display: flex;
    align-items: center;
    padding-left: 104px;
  }

  .charts {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }

  }
  .action {
    display: flex;
    align-items: center;
    & span {
      margin-left: 12px;
    }
  }
}

.wrapperDragPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .pagination {
    font-size: 14px;
    margin: 2px;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    background-color: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    box-sizing: border-box;
    border-radius: 2px;
    font-family: 'Roboto', sans-serif;
  }

  .pagination.currentPage {
    background: var(--highlight);
  }

  .wrapperSelect {
    display: flex;
    align-items: center;

    div {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}