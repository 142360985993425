.trackFiles {

  .wrapperTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleBlock {
      font-family: 'Bebas Neue', cursive;
      font-style: normal;
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
      color: #FFFFFF;
      margin-top: 28px;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 250px;
      @media (max-width: 767px) {
        font-size: 24px;
        margin-top: 0;
      }

      .wrapperButton {
        width: 150px;
      }

      span {
        border-bottom: 2px dashed #FFFFFF;
        margin-right: 8px;
      }
    }
  }
}

.buttonsPopUp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 24px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;

  button img {
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
