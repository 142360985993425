.detailPanelWrapper {
    margin: 20px;
}

.message {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.message:hover {
    white-space: pre-wrap;
}
