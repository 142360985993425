@mixin commonSearch {
  background: #2e2e2e url(../../../../assets/icons/search.svg) no-repeat scroll 17px 10px;
  padding-left: 40px;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.8;
  }
  &:focus {
    opacity: 0.8;
  }
}

.search {
  @include commonSearch;
}

.darkSearch {
  @include commonSearch;
  background: #171717 url(../../../../assets/icons/search.svg) no-repeat scroll 17px 10px;
  color: white;
  padding: 0 0 0 45px;

  &::placeholder {
  }
}


