h1 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  font-family: 'Bebas Neue', cursive;
  text-transform: uppercase;
}
.MobReturn {
  display: flex;
  justify-content: center;
  position: relative;
  & button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
