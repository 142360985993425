.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  .pageTitle {
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    line-height: 30px;
    font-style: normal;
    align-content: flex-start;
  }

  .searchWrapper {
    display: flex;
    justify-content: flex-end;

    input {
      width: 200px;
      margin-right: 36px;
    }
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & p {
    margin-bottom: 21px;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
  }

  .textInformation {
    margin-bottom: 15px;

    & div {
      text-align: center;
      font-size: 30px;
      font-family: 'Bebas Neue', cursive;
      font-weight: 700;
      color: var(--highlight);
    }

  }
}
