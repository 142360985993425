.getMySalesTableDataEngTotal {
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: bold;

  img {
    margin-right: 5px;
  }
}

.getCouponsTableDataCode {
  width: 89px;
  height: 36px;
  background: #FEC32D;
  border-radius: 2px;
  text-transform: uppercase;
  color: #000000;
  padding: 10px 15px 10px 15px
}

.getCouponsTableDataPrice {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & img {
    margin-right: 5px;
  }

  & > span {
    font-weight: 700;
  }
}

.getCouponsTableDataAction {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  cursor: pointer;
  align-content: center;

  .icons {
    min-height: 16px;
    min-width: 16px;
    font-size: 16px;
  }

  @media (max-width: 1180px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    justify-items: center;
  }
}

.getCashbackTableDataPrice {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & img {
    width: 14px;
    height: 16px;
  }
}

.getCashbackTableDataPriceRub {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & img {
    width: 12px;
    height: 12px;
  }
}

.getMySalesTableDataEngDateTime {
  width: 140px;
}

.getMySalesTableDataEngId {
  max-width: 20px;
}

.getDiscountsTableDataCode {
  min-width: 175px;
  @media (max-width: 1290px) {
    min-width: 120px;
  }
  @media (max-width: 1200px) {
    min-width: 60px;
  }
}

.getDiscountsTableDataClosedBeatsCount {
  max-width: 40px;
}

.getDiscountsTableDataName {
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: #3598DB;
    border-radius: 50%;
    margin-right: 12px;
  }
}

.getDiscountsTableDataActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & a {
    margin-right: 10px;
  }
}



