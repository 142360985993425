.errorMessage {
  color: red;
}

.radio {

  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;
  display: flex;
  align-items: center;
  input[type='radio'] {
    display: none;
    cursor: pointer;
  }
  label::before {
    cursor: pointer;
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    background: transparent;
    vertical-align: middle;
    margin-right: 9px;
  }


  input[type='radio']:checked +label::before {
    cursor: pointer;
    content: '';
    width: 14px;
    height: 14px;
    background-color: transparent;
    border: 1px solid white;
  }
  input[type='radio']:checked +label::after {
    cursor: pointer;
    content: '';
    border-radius: 50%;
    background-color: #ffffff;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 6px;
    left: 5px;
  }
  .label {
    position: relative;
  }
  .text {
    font-size: 14px;
    line-height: 16px;
    padding-top: 3px;
  }
}


