.box {
  display: flex;
  align-items: center;

  margin: 0 0 20px -10px;
}

.checkbox {
  margin-right: 5px;
}

.img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.beatInfo {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;

  .title {
    margin-bottom: 5px;

    color: #fff;
  }

  .published {
    color: #8d8d8d;
  }
}
