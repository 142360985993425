.BeatNameWrapper {
  max-width: 180px;

  .beatNameCollapsed {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: underline;
    font-weight: bold;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    img {
      margin-right: 6px;
      margin-top: 5px;
      cursor: pointer;
    }
  }

  .beatName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: underline;
    font-weight: bold;

    img {
      margin-right: 6px;
      cursor: pointer;
    }
    @media (max-width: 1180px) {
      max-width: 145px;
    }
    @media (max-width: 980px) {
      max-width: 130px;
    }
  }

}
