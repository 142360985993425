.player {
  font-size: 12px;
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--main-bg);
}

.playerContainer {
  display: grid;
  align-items: center;
  height: 75px;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 15px;
  padding-right: 15px;

}

.playerControls {
  justify-self: center;
}

.soundRangeContainer {
  justify-self: end;
  display: flex;
  .btnCollapsed {
    margin-left: 20px;
    cursor: pointer;
    width: 20px;
    height: 15px;
    margin-bottom: 20px;
    content: '';
    background: url('../../../assets/images/player-icons/down-arrow.svg') center no-repeat;
    background-size: cover;
  }
}

.btnOpenContainer {
  display: none;
}


@media (max-width: 768px) {
  .btnOpenContainer {
    display: flex;
    justify-self: end;
    .btnCollapsed {
      margin-left: 5px;
      cursor: pointer;
      width: 20px;
      height: 15px;
      content: '';
      background: url('../../../assets/images/player-icons/down-arrow.svg') center no-repeat;
      background-size: cover;
    }
  }

  .btnOpen {
    width: 9px;
    height: 30px;
    content: '';
    background: url('../../../assets/images/player-icons/more-info.svg') center no-repeat;
    background-size: cover;
    border: none;
  }

  .soundRangeContainer {
    display: none;
  }
}

@media (max-width: 576px) {
  .playerContainer {
    grid-template-columns: 1fr auto;
  }

  .playerControls {
    justify-self: end;
    grid-column: 2 / 3;
  }

  .btnOpenContainer {
    grid-column: 3 / 4;
    margin-left: 20px;
  }
}

