.container {
  position: absolute;
  z-index: 10;
  top: 0;

  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
  padding: 50px 0;

  color: white;
  background-color: var(--main-bg);
}

.progressBarContainer {
  margin-bottom: 30px;
}

.btnCloseContainer {
  text-align: center;
  margin: 0 auto;

  .btnClose {
    width: 40px;
    height: 25px;
    margin-bottom: 20px;

    content: '';

    background: url('../../../assets/images/player-icons/down-arrow.svg') center no-repeat;
    background-size: cover;
  }

}


