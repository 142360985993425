.description {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 22px;
  margin-top: -10px;
}

.fieldItemTitle {
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}

.fieldItemProduct {
  margin-bottom: 25px;
  //& .fieldSize {
  //  height: 131px;
  //  input {
  //    width: 100%;
  //    height: 100%;
  //  }
  //}

  & .rating {
    display: flex;
    align-items: flex-end;

    & > span {
      margin-right: 5px;
    }

    & label {
      font-size: 20px;
    }
  }
}

.fieldItemCurrency {
  margin-bottom: 25px;
}

.fields {
  display: grid;
  grid-template-columns: 50% 1fr;
  column-gap: 24px;
  margin-bottom: 23px;
}

.fieldItemCost {
  & .fieldSize {
    input {
      width: 100%;
      background: transparent;
      //border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 2px;
    }
  }
}

.ErrorMessage {
  color: red;
  font-size: 12px;
  font-weight: 700;
}

.commentBlock {
  margin-bottom: 29px;

  .commentTitle {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .commentText {
    font-size: 14px;
    line-height: 24px;
    opacity: 0.8;
  }

  .wrapperSend {
    display: flex;
    justify-content: flex-end;
  }
}

.btnBox {
  display: flex;
  justify-content: space-between;
}

