.coupon {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 22px 12px 26px 12px;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}

.list {
  margin-bottom: 30px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  & .descr {
    font-size: 14px;
    line-height: 16px;
  }

  & .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}

.statusBox {
  display: flex;
  align-items: center;
}

.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.btnBox {
  text-align: right;

  button {
    padding: 0;
  }

  .icons {
    min-height: 16px;
    min-width: 16px;
    font-size: 16px;
  }
}
