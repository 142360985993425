h2 {
  margin: 0;
  padding: 0;
}

.wrapper {
  margin-top: 36px;
  margin-bottom: 50px;
  min-width: 945px;
  @media (max-width: 1220px) {
    min-width: 845px;
  }
  @media (max-width: 1115px) {
    min-width: 780px;
  }
  @media (max-width: 1000px) {
    min-width: 120px;
  }
}

.general, .social, .secondary {
  padding: 21px 24px 30px 24px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  margin-bottom: 24px;

  & .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 32px;
  }

  .socialOptions, .secondaryOptions {
    row-gap: 24px;
  }

  & .generalOptions, .socialOptions, .secondaryOptions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 36px;

    @media (max-width: 1000px) {
      display: flex;
      flex-wrap: wrap;
    }

    & .generalItem, .socialItem, .secondaryItem {
      display: flex;
      flex-direction: column;

      @media (max-width: 1000px) {
        width: 100%;

        & label {
          margin-top: 8px;
          @media (max-width: 767px) {
            margin-top: 0;
          }
        }
      }

      .select {
        width: 48%;
        color: #FFFFFF;
        background-color: #171717;

        ::-webkit-scrollbar {
          width: 3px;
          border-left: 1px solid #171717;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #282828;
        }

      }

      & .itemTitle {
        font-size: 12px;
        line-height: 14px;
      }
    }

    & .nicknameCostLink {
      margin-top: 8px;
      color: #3598DB;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.selectWrapper {
  margin-top: 20px;
}

.secondary {
  margin-bottom: 0;

  .secondaryOptions {
    grid-template-columns: repeat(2, 1fr);
  }

  .secondaryItem:last-child {
    grid-area: 2/1/3/3;
  }
}

.btnsBox {
  margin-top: 24px;
  text-align: end;
}

.general {
  .generalItem {
    .labelNickname {
      .itemTitle {
        display: flex;
        align-items: center;
        gap: 5px;

        & button {
          width: 14px;
          height: 14px;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

