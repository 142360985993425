.contentWrapper{
  p {
    margin-bottom: 20px;
    text-align: center;
  }
}

.btnBox {
  display: flex;
  justify-content: space-around;
}