.MobCashback {
  width: 100%;
}
.total {
  padding: 20px 24px 58px 24px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  margin-bottom: 20px;
  & .title {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.4;
    margin-bottom: 12px;
    display: inline-block;
  }
  & .sum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .usd, .rub {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }
  }
}
.flag {
  margin-bottom: 24px;
  .itemField {
    display: flex;
    align-items: center;
    & > span {
      padding: 0 9px 0 0;
    }
    .checkBoxText {
      font-size: 14px;
      line-height: 16px;
      margin-right: 6px;
      margin-left: -4px;
    }
  }
}


