.licenseInput {
  color: #FFFFFF;
  margin-bottom: 8px;

  input {
    color: #FFFFFF;
      width: 102px;
  }

}
