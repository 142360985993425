.Snackbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 420px;
  height: 36px;
  border-radius: 2px;
  padding: 10px;

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & img {
      margin-right: 8px
    }

    & p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .close {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

}

.success {
  background: #30B800;
}

.error {
  background: #DC1749;
}

.warning {
  background: #DCA517;
}

.info {
  background: #282828;
}