//.Select {
//  font-family: 'Roboto', sans-serif;
//  font-size: 14px;
//  background: #282828;
//  border: none;
//  color: #FFFFFF;
//  padding: 10px 0px 10px 10px;
//
//}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;


  .Select {
    width: 100%;
    font-size: 14px;
    padding: 10px 0 5px 5px;
    height: 36px;
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 2px;
    background-position-x: 244px;
    color: #FFFFFF;
  }

  .noDefaultIcon {
    -webkit-appearance: none;
    background: #282828;
  }

  .icon {
    -webkit-appearance: none !important;
    color: var(--highlight);
    text-align: right;
    border-left: 0;
    margin: 0 0 0 -40px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    background: #282828;
    width: 15px;
    height: 16px;
    border: none;
    padding: 10px 17px 25px 17px;
  }
}


