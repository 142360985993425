.wrapperSelect {

  p {
    padding-top: 8px;
    color: #3598DB
  }

  & select {
    width: 100%!important;
  }

  .wrapperFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dateInner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;

      div {
        margin-right: 5px;

        div {
          div:first-child {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    .selectItem {
      //margin-bottom: 24px;
      width: 248px;
      z-index: 99999;
    }

    .titleItem {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
      display: inline-block;
    }

  }
}

.dialogTitle {
  font-size: 14px;
}

.btnBox {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}