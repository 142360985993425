
.MuiTypography-root {
  color: #FFFFFF;

  &.MuiTypography-caption {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
  }

}

.MuiButtonBase-root {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  &.Mui-disabled {

    &.MuiPickersDay-root {
      color: rgba(255, 255, 255, 0.2);

    }
  }

  &.MuiPickersDay-root {
    font-size: 14px;

    &.MuiPickersDay-today {
      border: none;
    }

    &.Mui-selected {
      background: #3598DB;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    opacity: 0.5;
  }
}


.MuiCalendarPicker-root  {

  button {
    color: #FFFFFF;
    background: #282828;
  }

}

.PrivatePickersFadeTransitionGroup-root {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}


