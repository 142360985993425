.btnBoxAdd {
  margin: 26px 0 29px 0;
}
.title {
  padding: 0;
  margin: 0 0 18px 0;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  font-family: 'Bebas Neue', cursive;
}
.information {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 24px 24px 42px 24px;
  margin-bottom: 36px;
}
.mainInfo {
  margin-bottom: 36px;
  .item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
    .descr {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
      display: inline-block;
    }
    .fieldSize {
      & input {
        width: 100%;
      }
    }
  }
}
.options {
  margin-bottom: 41px;
  .option {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    & .item {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      & span {
        //padding: 0 9px 0 0;
        font-weight: bold;
      }
      & .text {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        margin-left: -9px;
      }
    }
    & .descr {
      font-size: 14px;
      line-height: 24px;
      opacity: 0.5;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  & .listEng {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .newOptionBtn {
    color: #3598DB;
    text-decoration: underline;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
    align-self: flex-start;
  }
  & .listRus {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & .subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 14px;
    display: inline-block;
    align-self: flex-start;
  }
  & .item {
    display: flex;
    padding: 6px 15px 6px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  & .btnDrag {
    margin-right: 9px;
  }
  & .btnDelete {
    margin-left: 9px;
  }
  & .fieldSize {
    input {
      width: 100%;
    }
  }
}
.btnBox {
  display: grid;
  grid-template-columns: 110px 1fr;
  column-gap: 36px;
  & button:first-child {
    padding: 10px 20px;
    min-width: 100%;
  }
  & button:last-child {
    width: 100%;
    @media (max-width: 396px) {
      padding: 10px 18px 10px 18px;
    }
  }
}

