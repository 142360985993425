.wrapper {
  min-width: 600px;
  display: grid;
  grid-template-columns: 31% 28% 28% 14% auto;
  height: 60px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: #101010;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);
  padding-left: 12px;
  //padding: 18px 10px 10px 10px;

  @media (max-width: 1180px) {
    grid-template-columns: 35% 21% repeat(2, 17%) auto;
  }

  .title, .dateWrapper, .priceWrapper {
    display: flex;
    align-items: center;

    .dragElement {
      margin: 10px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    & a {
      margin-top: 3px;
    }

    .icons {
      margin-right: 15px;
    }
  }
}

.Mobile {
  margin-bottom: 12px;
}