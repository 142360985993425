.root {
    min-width: 100%;
    margin-top: 25px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.tableWrapper {
    min-width: 100%;
    margin-top: 0;
    background: white;
}

.red {
    background: #dd5758;
    padding: 4px;
    color: white;
    border-radius: 3px;
}
.renderHtmlWrapper {
    background: white;
    padding: 30px;
    border: 5px solid var(--highlight);
}
.info {
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
}

.form {
    background: white;
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.helpText {
    margin-top: 10px;
    width: 600px;
    color: red;
    font-weight: 700;
}

.helpText > span {
    color: darkgoldenrod;
}

.editorWrapper {
    width: 100%;
    margin-top: 10px;
}

.fromControl {
    margin-top: 10px;
}
