.wallets {
    cursor: pointer;
}
.wallets:hover {
    font-weight: 700;
}

.allStatistics {
    font-weight: 700;
    font-size: 18px;
    width: 350px;
    padding: 10px;
    margin-bottom: 10px;
}