.CouponItem {
  padding: 22px 12px 26px 12px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  margin-bottom: 12px;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 30px;
  }
  & .descr {
    font-size: 14px;
    line-height: 16px;
  }
  & .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}
.status {
  display: flex;
  align-items: center;
  & .value {
    margin-left: 8px;
    display: inline-block;
  }
  .Confirm {
    width: 8px;
    height: 8px;
    background: #0CBF43;
    border-radius: 50%;
    display: inline-block;
  }
  .Reject {
    width: 8px;
    height: 8px;
    background: #FF4242;
    border-radius: 50%;
    display: inline-block;
  }
  .Clarification {
    width: 8px;
    height: 8px;
    background: #6B8CFF;
    border-radius: 50%;
    display: inline-block;
  }
  .Wait {
    width: 8px;
    height: 8px;
    background: #FEC32D;
    border-radius: 50%;
    display: inline-block;
  }
}
.btnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    padding: 0;
    &:first-child {
      margin-right: 21px;
    }
  }
}
