.root {
    margin-bottom: 50px;
    min-width: 945px;

    .License {}

    padding-bottom: 50px;

    .wrapperButtonFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 36px;
    }
}

.back {
    margin-bottom: 40px;

    a {
        text-decoration: none;
        color: #FFFFFF;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    img {
        margin-right: 16px;
    }
}

.titleBlock {
    font-family: 'Bebas Neue', cursive;
    font-style: normal;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    color: #FFFFFF;
    margin-top: 37px;
}

.topWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;

    .pageTitle {
        font-family: 'Bebas Neue', cursive;
        font-size: 30px;
        line-height: 30px;
        font-style: normal;
        align-content: flex-start;
    }

    .searchWrapper {
        display: flex;
        justify-content: flex-end;

        input {
            width: 200px;
            margin-right: 36px;
            background-color: #282828;
            background-image: url('../../../assets/icons/search.svg');
            border: transparent;
        }
    }

    .searchWrapperArchive {
        margin-bottom: 1px;
    }
}

.tabsWrapper {
    margin-bottom: 24px;
}

.MobMyBeatsPageHeader {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .fieldSize {
        input {
            width: 166px;
        }
    }
}

.title {
    padding: 0;
    margin: 48px 0 18px 0;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    text-transform: uppercase;
    font-family: 'Bebas Neue', cursive;
}

.emptyMessage {
    text-align: center;
    color: white;
}

.buttonsPopUp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 24px;
}

.wrapperButton {
    margin: 0 0 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.wrapperButton>button {
    margin-right: 10px;
}

.paper {
    width: 100%;
    padding-bottom: 20px;
}

.inputWrapper {
    display: flex;
}

.formControl {
    margin: 10px 0 10px 0;
    display: flex;
    align-items: flex-end;
}

.setLinkInput {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
}

.wrapperInputPrice {
    display: flex;
    max-width: 200px;
    min-width: 200px;
    margin-right: 5px;

}

.pageCount {
    background: white;
    height: 40px;
}

.wrapperInputPrice>div {
    margin-right: 5px;

}

.information {
    margin-bottom: 10px;
    font-weight: bold;
}

.progress {
    margin-top: 10px;
}

.formControl>button {
    margin-left: 10px
}

.formControl .copyInput {
    width: 450px;
    height: 40px;
}

.wrapperPlayerInfo {
    display: flex;
    flex-direction: column;
}

.tags {
    display: flex;
    max-width: 450px;
    flex-wrap: wrap;
}

.tag {
    padding: 5px;
    background-color: #b9e1f6;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
}

.tag:hover {
    opacity: 0.8;
}

.release {
    max-width: 230px;
    margin-bottom: 40px;
}

.filesFormWrapper {
    width: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.filesFormWrapper>button {
    margin: 10px
}

.topForm {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.topForm>div {
    margin: 0 0 0 20px;
}

.title {
    margin: 10px;
    font-weight: 700;
}

.copyButton {
    opacity: 0.6;
    color: black
}

.copyButton:hover {
    opacity: 1;
    cursor: pointer;
    color: var(--highlight)
}

.formControl.inputLabel {
    flex-direction: column;
    align-items: flex-start;
}

.formControl.inputLabel>label {
    margin-bottom: 10px;
    font-size: 12px;
}

.wrapperImageBlock {
    padding-top: 50px;
}

.middleForm {
    display: flex;
    flex-wrap: wrap;
}

.checkbox {
    display: flex;
    flex-wrap: wrap;
}

.titleSettings {
    font-size: 24px;
    font-weight: 700;
    margin: 20px 0 20px 0
}

.wrapperDownloadOption {
    padding-top: 40px;
    margin-left: 50px;
}

.detailsForm {
    display: flex;
    flex-wrap: wrap;
}

.detailsWrapperForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sampleWrapper {
    display: flex;
    align-items: center;
}

.sampleWrapper>div {
    margin-right: 10px;
}


.sample {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sample>ul>li {
    margin-bottom: 10px
}

.deleteIcon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.dropArea {
    display: flex;
    background: #464545;
    border: 1px dashed rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    height: 155px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.dragArea {
    display: flex;
    flex-direction: column;
    background: #282828;
    border: 1px dashed rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    height: 155px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;

    img {
        margin-bottom: 22px;
    }
}

.containerWrapper {

    .dragMessage {
        display: flex;

        span {
            margin-right: 5px;
            margin-left: 5px;
        }
    }

}

.wrapperButtonPopup {
    margin: 0 0 20px 0;
}

.overflow {
    z-index: 11;
    border-radius: 12px;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.modalWrapper {
    padding: 20px;
    width: 500px;
}

.blockWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text>div {
    margin-top: 10px;
}

.blockWrapper>div {
    margin: 10px
}

.filesBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.filesBlock>div {
    width: 100%;
}

.priceBlock {
    padding: 0.5%;
}

.licenseWrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    right: 80px;
    bottom: 0;
}

.buttons>button {
    margin: 10px
}

.buttons>a {
    margin: 10px;
    text-decoration: none;
}

.breadcrumb {
    padding: 5px;
}

@media (max-width: 1654px) {
    .text {
        font-size: 12px;
    }

    .modalWrapper {
        width: 400px;
    }
}

@media (max-width: 1360px) {
    .text {
        font-size: 14px;
    }

    .blockWrapper {
        flex-direction: column;
    }

    .modalWrapper {
        width: 350px;
    }
}

@media (max-width: 1180px) {
    .root {
        min-width: 765px;
    }
}

@media (max-width: 1000px) {
    .root {
        min-width: 100%;
    }
}

@media (max-width: 375px) {
    .wrapperContent {
        width: 100%;
    }

    .text {
        font-size: 12px;
    }

    .modalWrapper {
        width: auto;
    }
}