.overflow {
  z-index: 11;
  border-radius: 12px;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.modalWrapper {
  //width: 532px;
  background: #171717;
  //border: 1px solid rgba(255, 255, 255, 0.07);
  //box-sizing: border-box;
  //border-radius: 4px;

  .modalHeader {
    border-bottom: 1px solid #4d4c4c;
    display: flex;
    justify-content: space-between;

    div {
      margin: 18px 18px 18px 18px;
      font-size: 18px;
      font-family: 'Roboto', sans-serif;
    }

    img {
      cursor: pointer;
    }
  }

  .dragAndDrop {
    //width: 484px;
    //margin-right: 24px;
    //margin-left: 24px;
    //margin-top: 24px
  }

  .progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .img {
      margin-right: 10px;
    }
  }

  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }

  .setLinkInput {
    margin-top: 10px;

    .link {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }


}

.wrapperButtonPopup {
  margin: 0 0 20px 0;
}

.information {
  margin-bottom: 10px;
  font-weight: bold;
}




