.buttonBox {
  position: relative;
  display: flex;
  align-items: center;
  .buttonTooltip {
    padding: 0;
    width: 12px;
    height: 12px;
  }
  .tooltip {
    position: absolute;
    top: 25px;
    right: -13px;
    padding: 8px 12px;
    width: 211px;
    background: #171717;
    border: 1px solid #5C5C5C;
    z-index: 15;
    border-radius: 4px;
  }
  .description {
    font-size: 12px;
    line-height: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-word;
    max-height: 176px;
  }
  .triangle {
    width: 11px;
    height: 11px;
    border-radius: 2px;
    position: absolute;
    transform: rotate(45deg);
    background: #171717;
    border-top: 1px solid #5C5C5C;
    border-left: 1px solid #5C5C5C;
    top: -5px;
    right: 12px;
  }
}
