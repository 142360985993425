.saleTrack {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 12px 12px 29px 12px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}
.track {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  & img {
    margin-right: 18px;
  }
  & div {
    display: flex;
    flex-direction: column;
  }
  & .trackName {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
  }
  & .published {
    font-size: 14px;
    line-height: 16px;
    color: #8D8D8D;
  }
}

.beatLicense {

    margin-left: 5px;
    color: #3598DB;
}
.actions {
  & .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    & .itemTitle {
      font-size: 14px;
      line-height: 16px;
    }
    & .itemLink {
      text-decoration-line: underline;
      color: #3598DB;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
