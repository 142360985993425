.dateBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  & .date {
    display: flex;
    align-items: center;
    & .text {
      font-size: 14px;
      line-height: 24px;
      margin-right: 9px;
    }
  }
}
.choose {
  height: 36px;
  margin-bottom: 24px;
}
.btnBox {
  margin-bottom: 12px;
  & button {
    width: 100%;
  }
}
