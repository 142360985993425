.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 25;
}

h3 {
  font-weight: normal;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modalContent {
  border-radius: 12px;
  background-color: white;
  transform: scale(0.5);
  transition: 0.4s all;
  width: auto;
}

.modalContent.active {
  transform: scale(1);
}

@media (max-width: 1445px) {
}

@media (max-width: 375px) {
}

@media (max-width: 815px) {
}
