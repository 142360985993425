.coinsItem {
  padding: 22px 12px 34px 12px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  & .description {
    font-size: 14px;
    line-height: 16px;
  }
  & .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}
