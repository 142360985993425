.progressBar {
  position: relative;

  display: block;

  width: 100%;
  height: 6px;

  cursor: pointer;

  background: rgba(196, 196, 196, .5);

  .loading {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    height: 100%;

    opacity: .3;
    background: var(--highlight);
  }

  .songLength {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 0;
    height: 100%;

    background: var(--highlight);

    &::before {
      position: absolute;
      top: 50%;
      right: -13px;

      box-sizing: content-box;
      width: 24px;
      height: 24px;

      content: '';
      transform: translateY(-35%);

      border-radius: 50%;
      background-image: var(--player-current-position);
      background-position: center;
      background-size: cover;
    }
  }
}
