.inputStyle {
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background: #282828;
  height: 36px;
  color: #FFFFFF;
  padding: 10px 17px 10px 17px;
  border: 1px solid transparent;
  transition: 0.3s ease-out;
  text-overflow: ellipsis;
  width: 100%;

  &:hover {
    opacity: 0.8;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }

  &:focus {
    opacity: 0.8;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }

  &:disabled {
    background: rgba(41, 41, 41, 0.5);
    color: rgba(255, 255, 255, 0.5);
    user-select: none;
    pointer-events: none;
  }
}

.labelStyle {
  display: block;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}

.errorMessage{
  border-color: #b73e3e;
}

.wrapperCopy {
  display: flex;

  .copyButton {
    -webkit-appearance:none!important;
    color:var(--highlight);
    text-align:right;
    border-left:0;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    height: 36px;
    background: #282828;
    border: none;
    padding: 10px 10px 10px 10px;
  }

  .copyButton.disabled {
    background: rgba(41, 41, 41, 0.5);
    white-space: nowrap;
  }
}