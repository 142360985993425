.select {
  [class$="-container"] {
    background-color: #171717;
    height: 36px;
    z-index: 26;
    color: #FFFFFF;

    &:hover {
      background-color: #282828;
      cursor: pointer;
    }
  }

  [class$="-control"] {
    background-color: #171717;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    box-shadow: none;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    border-radius: 2px;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  [class$="-singleValue"] {
    color: white;
  }

  [class$="-option"] {
    background-color: #171717;
    height: 36px;
    z-index: 26;
    color: #FFFFFF;

    &:hover {
      background-color: #282828;
      cursor: pointer;
    }
  }
}
.errorMessage {
  color: red;
}