.beatsHeader {
    //display: flex;
    //justify-content: flex-start;
    //align-items: center;
    display: grid;
    grid-template-columns: 31% 28% 28% 14% auto;
    height: 36px;
    background: #171717;
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #313030;
    @media (max-width: 1180px) {
        grid-template-columns: 35% 21% repeat(2, 17%) auto;
    }

    .beatCost, .date {
        display: flex;
        align-items: center;
    }

    .name {
        display: flex;
        align-items: center;
        //width: 400px;
        padding-left: 12px;
    }

    .charts {
        display: flex;
        justify-content: space-between;
        //width: 28vw;

        div {
            display: flex;
            align-items: center;
            //width: 175px;
            img {
                margin-right: 10px;
            }

            span {
                //width: 9vw;
                //width: 175px;
            }
        }

    }
    .action {
        display: flex;
        align-items: center;
        justify-content: center;
        //width: 175px;
    }
}

.boxBtn {
    & button {
        width: 100%;
        margin-bottom: 24px;
    }
}


.topWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;

    .pageTitle {
        font-family: 'Bebas Neue', cursive;
        font-size: 30px;
        line-height: 30px;
        font-style: normal;
        align-content: flex-start;
    }

    .searchWrapper {
        display: flex;
        justify-content: flex-end;

        input {
            width: 200px;
            margin-right: 36px;
        }
    }

}

.buttonsPopUp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 24px;
}

