.leaner {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  .fileName {
    margin-bottom: 6px;
  }

  .progressFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .progressCount {
      color: var(--highlight);
    }
    .sizeCount {
      opacity: 0.6;
      margin-top: 6px;
    }

    .progressFinished {
      color: #30B800;
    }

    .progressFailed {
      color: #DC1749;
    }

  }

  .progressBar {
    height: 3px;
    background-color: #383838;
  }

}

.wrapper{
  text-align: center;
}