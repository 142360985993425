.mobTrackLicenses {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 16px 12px 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fileName {
  display: flex;
  align-items: center;
  padding-left: 6px;

  & img {
    margin-right: 24px;
  }

  & span {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}

.fileInfo {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 33px;
  }

  & .text {
    font-size: 14px;
    line-height: 16px;
  }

  & .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}

.btnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
