* {
    box-sizing: border-box;
}

.isBusy {
    /*background: #b03f35;*/
    color: #FFFFFF !important;
    opacity: 0.2 !important;
    /*border-radius: 40px;*/
    /*pointer-events: none;*/
}

.tooltipBusy {
    /*background: #77DD77;*/
    position: absolute;
    bottom: 1px;
    left: 24px;
    border-radius: 50%;
    background-color: #3598DB;
    width: 3px;
    height: 3px;
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
    /*padding-bottom: 1px;*/
    /*font-size: 12px;*/
    /*font-weight: bold;*/
    /*color: #26bd04;*/
    /*z-index: 999;*/
}

.isBusyUser {
    background: #40bc2c;
    color: #f5fbff;
}


.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.colStart {
    justify-content: flex-start;
    text-align: left;

}

.colCenter {
    justify-content: center;
    text-align: center;
}

.colEnd {
    justify-content: flex-end;
    text-align: right;
}

.calendar {
    display: block;
    position: relative;
    /*width: 345px;*/
    height: 260px;
    cursor: pointer;
    /*padding: 20px 0 25px 0;*/
    background: #282828;
    border: 1px solid rgba(255, 255, 255, 0.07);
    box-sizing: border-box;
    border-radius: 4px;
}

.calendar.loaderCalendar {
    opacity: 0.5;
    pointer-events: none;
}

/*@media (max-width: 510px) {*/
/*    .calendar {*/
/*        width: auto;*/
/*        height: auto;*/
/*        padding: 5px 0 10px 0;*/
/*    }*/
/*}*/

.calendar__active {
    background-color: #ffffff;
    border: solid 1px transparent;
}

.calendar__currentLabel {
    display: block;
    border-radius: 0 3px 0 3px;
    width: max-content;
    padding: 0 5px;
    height: 20px;
    background-color: #c9cfd3;
    position: absolute;
    top: -1px;
    right: -1px;

    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: -0.2px;
    text-align: center;
    color: #ffffff;
}

.calendar .calendarHeader {
    display: flex;
    justify-content: space-around;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: #FFFFFF;
    padding: 17px 15px 15px 15px;
    text-transform: uppercase;
    line-height: 1;
}

.calendar .days {
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.3px;
    text-align: center;
    color: #7d8b8d;

}

.currentDay {
    background: var(--highlight);
    border-radius: 50%;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #FFFFFF;
}

.calendar .cell:hover {
    /*background-color: rgb(225, 226, 226);*/
    opacity: 0.7;
}

.calendar .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    width: 30px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.25s ease-out;
}

.calendar .cell .number {
    z-index: 10;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.9;
    font-weight: bold;
}

.calendar .userBusyDay {
    color: #3598DB !important;
}


.calendar .disabled {
    text-align: center;
    color: #FFFFFF;
    opacity: 0.2;
    pointer-events: none;
}

.calendar .cell span.firstDay {
    position: absolute;
    left: 5px;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-color: #0097e6;
    border-radius: 15px 0 0 15px;
    margin-left: 2px;
}

.calendar .cell span.middleDay {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-color: #0097e6;
}

.calendar .cell span.lastDay {
    position: absolute;
    right: 5px;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-color: #0097e6;
    border-radius: 0 15px 15px 0;
    margin-right: 2px;
}

.calendar .cell span.oneDayInterval {
    position: absolute;
    right: 3px;
    left: 8px;
    height: 100%;
    opacity: 0.3;
    background-color: #0097e6;
    border-radius: 15px 15px 15px 15px;
    margin-right: 2px;
}

.calendar .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: 30px;
    height: 30px;
    color: #FFFFFF;

}

.promotionButton {
    /*margin: 14px 20px 0 0;*/
    background-color: var(--highlight);
    font-weight: 600;
}

.promotionButton:hover {
    background-color: var(--highlight);
    /*opacity: 0.8;*/

}

.selectedDay {
    color: #FFFFFF;
}

.selectedDay:hover {
    opacity: 0.7 !important;
}

.calendarNavigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.monthAndYearNavigation {
    width: 45%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.wrapperForArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.nameOfMonth {
    width: 100px;
    text-align: center;
}

.selectWrapper {
    display: flex;
    margin-left: 19px;
    margin-right: 19px;
    position: relative;
    justify-content: left;
    align-items: center;
    max-width: 100%;
    max-height: 50px;
}

.select {
    z-index: 999;
    min-width: 240px;
    padding-left: 5px;
}

.calendarWrapperDisabled {
    pointer-events: none;
    opacity: 0.3
}

.calendarWrapper {
    margin-top: 20px;

}

.dialogTitle {
    font-size: 14px;

}

.busyDayInfo {
    margin: 10px;
}

.totalSlots {
    opacity: 0.5;
}

@media (max-width: 510px) {
    /*.calendarWrapper {*/
    /*    margin-top: 15px;*/
    /*}*/

    /*.dialogTitle {*/
    /*    font-size: 14px*/
    /*}*/

    /*.selectWrapper {*/
    /*    margin-left: 9px;*/
    /*    margin-right: 9px;*/
    /*    max-height: 20px;*/
    /*}*/

    /*.select {*/
    /*    min-width: 140px;*/
    /*    font-size: 13px;*/
    /*}*/

    /*.nameOfMonth {*/
    /*    font-size: 12px;*/
    /*}*/

    /*.button {*/
    /*    font-size: 10px*/
    /*}*/

    .calendar .col {
        width: 38px;
        height: 30px;
        font-size: 10px;
    }

    .calendar .cell .number {
        font-size: 12px;
    }

    /*.busyDayInfo > div > span {*/
    /*    font-weight: bold;*/
    /*    font-size: 12px;*/
    /*}*/

    /*.tooltipBusy {*/
    /*    position: absolute;*/
    /*    right: -2px;*/
    /*    top: 0;*/
    /*    !*background: #77DD77;*!*/
    /*    border-radius: 50%;*/
    /*    padding-left: 4px;*/
    /*    padding-right: 4px;*/
    /*    font-size: 9px;*/
    /*    font-weight: bold;*/
    /*    color: #26bd04;*/
    /*}*/

    /*.calendar .cell:hover {*/
    /*    background-color: rgb(225, 226, 226);*/
    /*}*/

}

























