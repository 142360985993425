.wrapper {
  & > div {
    font-size: 12px;
    text-align: center;
  }
}

.container {

  .preloader {
    margin-top: 5px;
    margin-bottom: 5px;
  }


  .back {
    margin-bottom: 40px;

    a {
      text-decoration: none;
      color: #FFFFFF;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    img {
      margin-right: 16px;
    }
  }
}
.title {
  margin: 0;
  padding: 0;
  font-family: 'Bebas Neue', cursive;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
}
.licensesTop {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 12px;
}
.settings {
  padding: 24px 24px 42px 24px;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  margin-bottom: 36px;

}
.textFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 31px;
  & .textFieldItem {
    display: flex;
    flex-direction: column;
    & label {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 14px;
    }
    //& .textarea {
    //  resize: none;
    //  background: #282828;
    //  border-radius: 2px;
    //  border: none;
    //}
  }
}
.selections {
  padding: 32px 0;
  & .selectionBox {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    .selectionItem {
      & label {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .description {
      font-size: 14px;
      line-height: 24px;
      opacity: 0.5;
    }
  }
}
  .subtitle {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 20px 0;
    padding: 0;
  }

  .option {
    display: flex;
    justify-content: space-around;
  }
  .optionsBox {
    display: grid;
    //grid-template-columns: repeat(2, 1fr);
    column-gap: 31px;
    margin-bottom: 12px;
    & .optionItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 16px;
      box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);

      & input {
        width: 100%;
      }
      //.optionArea {
      //  background: #282828;
      //  border-radius: 2px;
      //  resize: none;
      //  border: none;
      //  width: 100%;
      //}
      .drag, {
        padding: 0;
        margin-right: 16px;
      }
      .delete {
        padding: 0;
        margin-left: 28px;
      }
    }
  }
  .newOptionBtn {
    color: #3598DB;
    text-decoration: underline;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
  }
.buttonsBox {
  display: flex;
  justify-content: space-between;
}
