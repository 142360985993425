.salesTableHeader {

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 12px;
  & h3 {
    text-align: left;
    opacity: 0.4;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    padding: 0;
  }
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  & span {
    grid-area: 1/1/3/2;
  }
  & .saleTableCellUSD {
    grid-area: 1/2/2/6;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 10px;
    & span:first-child {
      grid-area: 1/1/2/2;
    }
    & span:nth-child(2) {
      grid-area: 1/2/2/3;
    }
    & span:nth-child(3) {
      grid-area: 1/3/2/4;
    }
    & span:last-child {
      grid-area: 1/4/2/5;
    }
  }
}
.saleTableCellRub {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-area: 2/2/3/6;
  & span:first-child {
    grid-area: 1/1/2/2;
  }
  & span:nth-child(2) {
    grid-area: 1/2/2/3;
  }
  & span:nth-child(3) {
    grid-area: 1/3/2/4;
  }
  & span:last-child {
    grid-area: 1/4/2/5;
  }
}
.usdPrice {
  display: flex;
  align-items: center;
  & img {
    width: 13px;
    height: 26px;
    margin-right: 6px;
  }
}
.rubPrice {
  display: flex;
  align-items: center;
  & img {
    width: 16px;
    height: 26px;
    margin-right: 6px;
  }
}
