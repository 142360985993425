.sound {
  display: flex;
  align-items: center;

  .soundIcon {
    width: 16px;
    margin-right: 8px;
  }

  .soundRange {
    width: 80px;
    height: 4px;
    cursor: pointer;
    -webkit-transition: .2s;
    transition: opacity .2s;

    border-radius: 2px;
    outline: none;
    background: linear-gradient(90deg, var(--highlight) 50%, #c8c8c8 50%);

    -webkit-appearance: none;;

    @mixin thumb() {
      width: 10px;
      height: 10px;

      border-radius: 50%;
      background-color: var(--highlight);
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      @include thumb;
    }

    &::-moz-range-thumb {
      @include thumb;
    }
  }
}
