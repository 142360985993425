.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;

  .pageTitle {
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    line-height: 30px;
    font-style: normal;
    align-content: flex-start;
  }

  .searchWrapper {
    margin-bottom: 36px;
    input {
      width: 100%;
    }
  }

  @media (max-width: 375px) {
    display: block;
  }
}

.buttonsPopUp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 24px;
}

.wrapperCouponAdd {

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 37px;

  & button {
    margin-left: 24px;
  }

  .selectItem {
    width: 200px;
    z-index: 15;
  }

  .titleItem {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
    display: inline-block;
  }
}

