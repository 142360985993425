.wrapper {
  padding: 0 0 0 36px;
  margin-top: 24px;
}

.bindingCardWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.btnBox {
  position: relative;
  top: 22px;

  button {
    width: 100%;
  }

  @media (max-width: 1000px) {
    width: 100%;
    position: static;
  }
}

.extra {
  font-size: 16px;
  line-height: 24px;
  word-break: break-all;
  margin-bottom: 20px;
  text-align: justify;
  
  .link {
    color: #3598DB;

    &:hover {
      text-decoration: underline;
    }
  }
}

.termText {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: justify;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}