.wrapperPage {
  margin-bottom: 30px;

  .topWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;

    .pageTitle {
      font-family: 'Bebas Neue', cursive;
      font-size: 30px;
      line-height: 30px;
      font-style: normal;
      align-content: flex-start;
    }

    .searchWrapper {
      display: flex;
      justify-content: flex-end;

      input {
        width: 200px;
        margin-right: 36px;
      }
    }
  }

  .Statistics {

    & > div {
      margin-bottom: 19px;
    }
    .buttonWithdraw {
      text-align: right;
      margin-bottom: 36px;
    }
  }
}

