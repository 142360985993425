.MySalesTrackInfo {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 22px 12px 24px 12px;
  & .item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
    &:nth-child(5) {
      margin-bottom: 34px;
    }
    & .title {
      font-size: 14px;
      line-height: 16px;
    }
    & .trackName {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-decoration-line: underline;

    }
    & .value {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      img {
        margin-right: 3px;
      }
    }
  }
  .boxBtnDetails {
    text-align: end;
    & button {
      padding: 10px 18px 10px 17px;
    }
  }
}

.BeatNameWrapper {
  max-width: 350px;

  .beatNameCollapsed {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    img {
      margin-right: 6px;
      margin-top: 5px;
      cursor: pointer;
    }

    & > div > div {
      margin-bottom: 18px;
    }
  }

  .beatName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: underline;

    img {
      margin-right: 6px;
      cursor: pointer;
    }
    @media (max-width: 1180px) {
      max-width: 350px;
    }
    @media (max-width: 980px) {
      max-width: 250px;
    }
  }

}
