$base-font-size: 12px;
$title-font-size: 1.3em;

$primary-color-dark:   #49599a;
$primary-color:        #1f1f1f;
$primary-color-light:  #aab6fe;
$primary-color-text:   #FFFFFF;
$accent-color:         #03A9F4;
$primary-text-color:   #212121;
$secondary-text-color: #757575;
$divider-color:        #e4e4e4;
$today-bg-color:      var(--highlight);

// import mixin
@import "../../../../node_modules/@y0c/react-datepicker/assets/styles/_mixin.scss";
// import app scss
// if you want other style customize
// app.scss copy & rewrite !
@import "./app.scss";
