h1, h2 {
  margin: 0;
  padding: 0;
}
.header {
  display: flex;
  justify-content: space-between;
}
.title {
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  font-family: 'Bebas Neue', cursive;
  margin-bottom: 14px;
}
.amountBox {
  display: flex;
  flex-direction: column;
  & .subtitle {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.4;
    margin-bottom: 12px;
  }
  & .price {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    & img {
      margin-right: 4px;
      width: 16px;
      height: 26px;
    }
  }
}
.checkBox {
  display: flex;
  align-items: center;
  span {
    padding: 0;
  }
  & .checkBoxText {
    font-size: 14px;
    line-height: 16px;
    margin: 0 9px 0 6px;
  }
}

