.TrackDetails {

  .contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 24px 36px 24px;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;

    .selectWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .select {
        width: 32%;
        color: #FFFFFF;
        background-color: #171717;
        ::-webkit-scrollbar{width:3px;border-left:1px solid #171717;}
        ::-webkit-scrollbar-thumb{background-color:#282828;}

        & > div {
          margin-bottom: 10px;
        }
        @media (max-width: 900px) {
          width: 100%;
        }
      }
      @media (max-width: 900px) {
        justify-content: flex-start;
        flex-direction: column;
      }
    }

    & > div {
      margin-bottom: 10px;
    }

  }
}

