.wrapper{
  width: 80vw;
  position: relative;
}

.Paper {
  margin-top: 20px;
}

.datePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Table {
  margin: 10px;
}

@media (max-width: 600px) {
  .wrapper {width: 95vw;}
}
