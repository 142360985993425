.wrapperPagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;

  .pagination {
    font-size: 14px;
    margin: 2px;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    background-color: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    box-sizing: border-box;
    border-radius: 2px;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
  }

  .pagination.currentPage {
    background: var(--highlight);
  }

  .pagination.dotsStyle{
    cursor: auto;
  }

  .wrapperSelect {
    display: flex;
    align-items: center;

    div {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}
