.selectField {
  margin-bottom: 24px;
  div > select {
    background: transparent;
  }
}
.btnBox {
  margin-bottom: 36px;
  button {
    width: 100%;
  }
}
