.beatItem {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 4px;
  padding: 12px 12px 26px 12px;
  margin-bottom: 12px;
  margin-top: 12px;

  &:last-child {
    margin-bottom: 0;
  }

}

.beatBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}

.beatInfo {
  display: flex;
  align-items: center;

  .track {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .trackDate {
    font-size: 14px;
    line-height: 16px;
    color: #8D8D8D;
  }

  .imageWrapper {
    position: relative;
    min-width: 50px;
    min-height: 50px;

    .beatImage {
      margin-right: 18px;
      position: relative;
      max-width: 50px;
      max-height: 50px;
    }

    .beatControl {
      img {
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 15px;
        top: 15px;
        opacity: 0.8;
        transition: 0.3s ease-in;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.dragElement {
  align-self: flex-start;

  .disabled {
    filter: invert(0.7)
  }
}

.description {
  margin-bottom: 24px;

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .itemText {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
    }
  }

  .itemCount {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & img {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  & a {
    display: flex;
    align-items: center;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}