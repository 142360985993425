.root {
    width: 65vw;
}

.settingsWrapper {
    margin-top: 20px;
}
.tableWrapper {
    margin-top: 20px;
    margin-bottom: 40px;
}
.wrapperAmount {
    margin-top: 50px;
    padding: 30px;
    font-size: 24px;
    font-weight: 700
}

.item > span {
    font-weight: 700;
}

@media (max-width: 1024px) {
    .wrapperAmount {
        font-size: 18px;
        font-weight: 700
    }

    .root {
        width: 95vw;
    }


}
