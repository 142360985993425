.mainFormWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: 20px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;

  .fieldsLeft {

    &>div {
      margin-bottom: 24px;
      position: relative;
      display: grid;
    }

    .beatName {

      &>div {
        margin-bottom: 10px;
      }
    }

    .tags {

      &>div {
        margin-bottom: 10px;
      }

      input {
        @media (max-width: 1000px) {
          margin-right: 18px;
          width: 100%;
        }
      }

      button {
        @media (max-width: 1000px) {
          min-width: 113px;
          padding: 10px 25px;
          white-space: nowrap;
        }
      }
    }
  }

  .fieldsCenter {
    margin-left: 36px;

    @media (max-width: 767px) {
      margin: 0 auto 24px auto;
    }

    &>div {
      margin-bottom: 10px;
    }
  }

  .fieldsRight {
    margin-left: 36px;
    height: 100%;

    @media (max-width: 767px) {
      margin: 0 auto;
    }

    .wrapperImage {
      text-align: center;

      .noImage {
        min-width: 162px;
        min-height: 162px;
        border-radius: 4px;
        background: #282828 url("../../../../../assets/icons/no-image.png") no-repeat center;
      }

      img {
        width: 162px;
        height: 162px;
        margin-bottom: 16px;
      }

      .supportsText {
        color: #686868;
      }

      &>div {
        margin-bottom: 32px;
      }
    }
  }
}