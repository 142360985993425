.playerControls,
.playerControlsMob {

  display: flex;
  align-items: center;
  justify-content: center;

  @mixin prevBtn() {
    display: inline-block;

    width: 18px;
    height: 18px;
    cursor: pointer;
    content: '';
    border: none;

    background: url('../../../assets/images/player-icons/next-track-white.svg');
    background-size: cover;
  }

  .prevBeatButton,
  .prevBeatButtonMob {
    transform: rotate(180deg);

    @include prevBtn;

    &--mob {
      background: url('../../../assets/images/player-icons/next-track-white.svg') center no-repeat;
    }
  }

  .nextBeatButton,
  .nextBeatButtonMob {
    @include prevBtn;

    &--mob {
      background: url('../../../assets/images/player-icons/next-track-white.svg') center no-repeat;
    }
  }

  .playBeatButton {
    margin: 0 30px;
    border: none;
    &:after {
      display: inline-block;
      cursor: pointer;
      width: 50px;
      height: 50px;

      content: '';

      background-size: cover;
    }
  }

  .pause {
    background: var(--player-pause-icon) center no-repeat;
  }

  .play {
    background: var(--player-play-icon) center no-repeat;
  }
}

@media (max-width: 576px) {
  .playerControls {
    .playBeatButton {
      margin: 0 10px;

      &::after {
        width: 30px;
        height: 30px;
      }
    }

    .prevBeatButton,
    .nextBeatButton {
      width: 10px;
      height: 10px;
    }
  }
}
