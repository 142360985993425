.contentItem {
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}
.contentBox {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.contentTitle {
  border-bottom: 1px dashed #FFFFFF;
  font-size: 25px;
  line-height: 25px;
  font-family: 'Bebas Neue', cursive;
  display: inline-block;
  padding-right: 8px;
}
