.listWrapper {
  height: 40%;
  border-radius: 5px;
}

.cardItemWrapper {
  display: flex;
}


.tooltip {
  position: relative;

  img {
    bottom: 12px;
  }
}

.icons{
  position: relative;
  top:8px;
}