.button {
  width: 195px;
  height: 108px;
  background: #171717;
  margin-bottom: 24px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
}

.isSelected {
  border: 1px solid #3598DB;
}

.icon{
  width: 100%;
  height: 100%;
  object-fit: contain;
}