.wrapper {
  display: flex;
  align-items: center;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
}

.circle.Confirmed {
  background-color: #0CBF43;
}

.circle.Approved {
  background-color: #0CBF43;
}

.circle.NotActive {
  background-color: #afafaf;
}

.circle.Waiting {
  background-color: #FEC32D;
}

.circle.Rejected {
  background-color: #FF4242;
}

.circle.Clarification {
  background-color: #6B8CFF;
}

.circle.Closed {
  background-color: #FF4242;
}

.circle.Active {
  background-color: #0CBF43;
}
.textStatus {

  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

