.wrapper {
  min-width: 945px;
  @media (max-width: 1175px) {
    min-width: 845px;
  }
  @media (max-width: 1075px) {
    min-width: 780px;
  }
  .back {
    margin-bottom: 40px;

    a {
      text-decoration: none;
      color: #FFFFFF;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    img {
      margin-right: 16px;
    }

  }
}

.informationTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.paidDate {
  & .saleID {
    margin-right: 24px;
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 2px;
    display: inline-block;
    padding: 9px 28px 8px 28px;
  }
}
.statusContainer {
  & .statusText {
    margin-right: 12px;
    font-weight: bold;
  }
  & .status {
    background: #171717;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 2px;
    display: inline-block;
    padding: 10px 22px 10px 21px;
  }
}
.title {
  margin: 0 0 38px 0;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  font-family: 'Bebas Neue', cursive;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}
.buyerInfo {
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 19px;
  @media (max-width: 1075px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.buyerText, .buyerEmail, .buyerFullName {
  font-weight: bold;
  margin-right: 12px;
}
.buyer > a, .email > a {
  color: #3598DB;
  text-decoration: underline;
}
.informationBottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  padding-left: 32px;
  @media (max-width: 1075px) {
    padding-left: 16px;
  }
}
.summary {
  flex-basis: 60%;
  margin-right: 13px;
  & .summaryTitle {
    padding: 20px 0 0 0;
    margin: 0 0 20px 0;
    text-align: left;
  }
  & .tableHeader {
    font-weight: bold;
    font-size: 12px;
    background: #171717;
    padding: 12px 12px 10px 12px;
    display: grid;
    grid-template-columns: 77% 1fr;
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);
  }
  & .tableBeat {
    display: grid;
    align-items: center;
    grid-template-columns: 77% 1fr;
    padding: 5px 12px 5px 12px;
    font-size: 14px;
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);
  }
  & .beatInfo {
    display: flex;
    align-items: center;
  }
  & .beatText {
    display: flex;
    flex-direction: column;
    margin-left: 17px;
      .beatName {
      font-weight: bold;
        margin-bottom: 3px;
    }
    .beatLicense {
      color: #8D8D8D;
      span {
        margin-left: 5px;
        color: #3598DB;
      }
    }
  }
  .contractLink {
    color: #3598DB;
    text-decoration: underline;
  }
}

.total {
  flex-basis: 40%;
  padding: 24px 24px 19px 24px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & .pointsItem {
    display: flex;
    justify-content: space-between;
    padding: 11px 0 13px 0;
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);
    .itemText {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
    }
    .itemValue {
      font-weight: bold;
      font-size: 16px;
      display: flex;
      align-items: center;
      img {
        width: 9px;
        height: 19px;
        margin-left: 3px;
      }
    }
  }
  & .totalPrice {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 52px;
    .totalPriceText {
      font-size: 18px;
    }
    .totalPriceValue {
      font-size: 30px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 29px;
        margin-right: 3px;
      }
    }
  }
}

