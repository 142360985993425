.wrapper{
  width: 80vw;
  position: relative;
  margin-top: 20px
}
@media (max-width: 600px) {
  .wrapper {width: 100%;}
}

.wrapperTable {
  background: white;
  margin-top: 15px;
  border-radius: 3px;
}
.wrapperFilter {
  display: flex;
  padding: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.wrapperRangePicker {
  margin-right: 20px;
}

.wrapperBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 0 5px 0 rgba(148,148,148,1);
  border-radius: 5px;
  font-size: 13px
}

/*.wrapper {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

.wrapperSelect {

  width: 250px;
}

.promotionButton {
  background-color: var(--highlight);
  font-weight: 600;
}
.promotionButton:hover {
  background-color: var(--highlight);
  /*opacity: 0.8;*/

}

.titlePeriod {
  margin-bottom: 10px;
  font-weight: bold;
}

.deletePopupButton {
   color: black;
 }


/*@media (max-width: 600px) {*/
/*  .wrapper {width: 100%;}*/
/*}*/
