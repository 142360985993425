@font-face {
    font-family: 'Bebas Neue';
    src: local("Oxygen"), url("./UI/assets/fonts/BebasNeue-Bold.ttf") format("truetype"),
    url("./UI/assets/fonts/BebasNeue.otf") format("otf"),
    url("./UI/assets/fonts/BebasNeue.ttf") format("ttf"),
    url("./UI/assets/fonts/BebasNeue.ttf") format("truetype"),
    url("./UI/assets/fonts/BebasNeue.otf") format("opentype");
    font-style: normal;
    font-display: block;
}

body {
    margin: 0 auto;
    padding: 2px;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #101010;
    color: #FFFFFF;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:active, button:focus {
    outline: none;
}

button::-moz-focus-inner {
    border: 0;
}

button {
    border: none;
}

input:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
}

a {
    text-decoration: none;
}

.css-26l3qy-menu {
    background-color: #171717 !important;
}

::-webkit-scrollbar {
    width: 3px;
    border-left: 1px solid #171717;
}

::-webkit-scrollbar-thumb {
    background-color: #494949;
}

.rec.rec-arrow {
    border-radius: 0;
    background: none;
}

.rec.rec-arrow:hover {
    transition: none;
    background-color: black;
}

.rec.rec-arrow:disabled {
    visibility: hidden;
    transition: none;
}

.rec-carousel-item:focus {
    outline: none;
    transition: none;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #FFFFFF !important;
    font-weight: 700;
}

.MuiTableSortLabel-root:hover {
    color: #FFFFFF !important;
    opacity: 0.9;
    font-weight: 700;
}

.MuiTableSortLabel-icon {
    color: #FFFFFF !important;
}

.MuiTableRow-head {
    font-weight: 700!important;
    color: #FFFFFF !important;
}

.material-icons {
    color: #FFFFFF !important;
}

.MuiTab-textColorInherit.Mui-selected {
    color: #3598DB
}

.MuiTabs-flexContainer.MuiTabs-centered {
    border-bottom: 2px solid rgb(128, 128, 128);
}

.Mui-disabled > input {
    color: #FFFFFF !important;
    -webkit-text-fill-color: #b2b2b2 !important;
}

.MuiButtonBase-root {
    font-weight: 700!important;
}

.MuiTabs-centered {
    justify-content: space-around !important;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone.MuiTableCell-sizeSmall {
    border-bottom: 1px solid #282828;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone.MuiTableCell-sizeSmall > div > button {
    margin-left: 10px;
}

.MuiDrawer-paper {
    background-color: #171717 !important;
}

.lg-img-wrap{
    background-color: inherit !important;
}

.lg-backdrop.in {
    opacity: 0.5 !important;
}