.PageWrapper {
    width: 90vw;
    padding: 20px 50px;
}

@media (max-width: 1024px) {
    .PageWrapper {
        width: 100vw;
        padding: 0 0;
    }
}
