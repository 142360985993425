.App {
  display: flex;
  justify-content: center;

  margin-top: 76px;
}

.menuWrapper {
  margin-right: 30px;
}

@media (max-width: 1139px) {
  .menuWrapper {
    position: fixed;
    z-index: 1201;
    top: 12px;
    left: 12px;

    margin-right: 0;
  }
  .menuWrapper button {
    transition: .3s ease-in;
  }
  .menuWrapper button:hover {
    background-color: #fff;
  }
}

@media (max-width: 1000px) {
  .App {
    display: block;

    margin-top: 0;
    padding-top: 56px;
    padding-bottom: 26px;
  }
}

/* COMMON STYLES */

/* 1. Heading */
.commonTopWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 36px;

  &--widgetBlockTitle {
    margin-bottom: 25px;
  }

  .commonPageTitle {
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    font-style: normal;
    line-height: 30px;

    align-content: flex-start;
  }

  .commonSearchWrapper {
    display: flex;
    justify-content: flex-end;

    input {
      width: 200px;
      margin-right: 36px;
    }
  }
}

/* 2. Arrow back */
.commonArrowBack {
  margin-bottom: 40px;

  a {
    font-size: 14px;
    line-height: 16px;

    cursor: pointer;
    text-decoration: none;

    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    margin-right: 16px;
  }
}

/* 3. Beat info wrapper */
.commonBeatInfoWrapper {
  display: flex;
  align-items: flex-start;

  border-radius: 2px;
  background: #282828;

  img {
    padding: 20px;
  }

  .commonBeatInfo {
    margin-bottom: 12px;
    padding-top: 20px;
    padding-right: 20px;

    .commonBeatFullName {
      font-weight: 700;

      width: 150px;
      margin-bottom: 12px;

      white-space: normal;
    }

    span {
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
    }
  }
}
