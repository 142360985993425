.card {
  display: grid;
  grid-template-columns: 35% 45% auto;
  position: relative;
  border-bottom: 1px solid #313030;
  color: #ffffff;
  align-items: center;
  padding: 3px 0;
  @media (max-width: 1279px) {
    grid-template-columns: 39% 54% auto;
  }
  
  .beatInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 16px;

    .dragElement {
      margin: 10px;
      
      .disabled {
        filter: invert(0.7)
      }
    }

    div {
      margin-left: 10px;
    }

    .beatTitle {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 700;
      white-space: normal;
      max-width: 230px;
      @media (max-width: 1180px) {
        max-width: 145px;
      }
      @media (max-width: 980px) {
        max-width: 130px;
      }
    }

    .imageWrapper {
      position: relative;
      min-width: 50px;
      min-height: 50px;

      .beatImage {
        position: relative;
        max-width: 50px;
        max-height: 50px;
      }

      .beatControl {
        img {
          cursor: pointer;
          display: flex;
          justify-content: center;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 15px;
          top: 15px;
          opacity: 0.8;
          transition: 0.3s ease-in;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .publicationInfo {
      display: flex;
      align-items: center;
      justify-self: flex-start;
      min-width: 200px;
      max-width: 200px;
      font-weight: 400;
      color: #8D8D8D;
    }

    .titleWrapper {

    }
  }

  .beatChart {
    display: grid;
    grid-template-columns: 33% repeat(2, 1fr);
    align-items: center;
    @media (max-width: 1180px) {
      grid-template-columns: 33% repeat(2, 1fr);
    }
  }

  .actions {
    display: none;
    @media (min-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      cursor: pointer;

      .icons {
        font-size: 16px;
      }

      .publishIcon {
        margin: 5px;
        color: #FFFFFF;
      }
    }
  }
}

.smallDesktopViewActions {
  position: relative;
  @media (min-width: 1280px) {
    display: none;
  }

  & button {
    padding: 0;
  }
}

.tooltip {
  display: block;
  position: absolute;
  top: 35px;
  left: -80px;
  padding: 24px 16px 24px 20px;
  width: max-content;
  background: #171717;
  border: 1px solid #5C5C5C;
  z-index: 15;
  border-radius: 4px;
  @media (max-width: 767px) {
    width: 300px;
  }
}

.description {
  font-size: 14px;
  line-height: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}

.triangle {
  width: 11px;
  height: 11px;
  border-radius: 2px;
  position: absolute;
  transform: rotate(45deg);
  background: #171717;
  border-top: 1px solid #5C5C5C;
  border-left: 1px solid #5C5C5C;
  top: -5px;
  left: 86px;
}

.actionsList {
  padding: 0;
  margin: 0;
  list-style: none;

  & li {
    display: flex;
    align-items: center;
    margin-bottom: 21px;

    img {
      margin-right: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & a {
      display: flex;
      align-items: center;
      margin-right: 16px;
      color: #ffffff;
      }
  }
}
