.styledTabs {
  display: flex;
  color: #FFFFFF;
  background-color: transparent;
  text-transform: none;

  :global .MuiTabs-indicator {
    background-color: #3598DB;
    text-transform: none;
  }
}

.styledTab {
  text-transform: none !important;
  color: #FFFFFF;
  font-weight: 700 !important;
  font-size: 16px !important;
  &:focus {
    opacity: 1;
  }

  padding-left: 38px;
  padding-right: 38px;
}