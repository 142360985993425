.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 130px;

  .pageTitle {
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    line-height: 30px;
    font-style: normal;
    align-content: flex-start;
    @media (max-width: 900px) {
      display: none;
    }
  }

}

.compilationCount {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  display: flex;
  align-items: center;

  .text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-right: 5px;
  }

  & span {
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
  }

}

.imageFon {
  width: 945px;
  height: 140px;
  //background-color: var(--highlight);

  & img {
    position: absolute;
    background-color: var(--highlight)
  }
}

.wrapperPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapperButton {
  display: flex;
  justify-content: center;
  align-items: center;

    @media (max-width: 900px) {
      width: 100%;
      flex-wrap: wrap;
    }


  & button {
    width: 200px;
    height: 36px;
    margin: 10px;
      @media (max-width: 900px) {
        width: 100%;
      }

  }
  //& button:last-child {
  //  @media (max-width: 900px) {
  //    display: none;
  //  }
  //}
}
